<script setup lang="ts">

import {useQuery} from "@vue/apollo-composable";
import {FormKit, submitForm} from "@formkit/vue"
import {FormQuery} from "../../../gql/queries/formie"
import {computed, ref} from "vue";
import {FormIntegrationsType, FormQueryQuery} from "../../../gql/types/types";
import FormieField from "./FormieField.vue";
import {VServices} from "@libVue/base";
import {useFormStore} from "../../../stores/formStore"
import {TranslationService} from "../../../services/translation.service";
import {flatMap, isPlainObject} from "lodash-es";
import axios from "axios";
import {BASEURL} from "@libTs/cms.globals";

import { setErrors, reset } from '@formkit/vue'

const {formname, gqlToken} = defineProps({
  formname: {
    type: String,
    required: true
  },
  gqlToken: {
    type: String,
    required: true
  }
})
const SERVICES = VServices({translation: TranslationService})
const errors = ref([])
const {result, error,loading} = useQuery<FormQueryQuery>(FormQuery, {
  handle: formname
}, {
  errorPolicy: "all",
  context: {
    headers: {
      'Authorization': `Bearer ${gqlToken}`
    }
  }
})
const formValues = ref()
const form = computed(() => {
  return result.value?.form
})

const t = (key: string) => {
  return SERVICES.translation.t(key)
}
const createSubmission = async () => {
  const flatFormValues = flattenFormValues(formValues.value)
  const cleanFormValues=await createMissingValues()
  const mutationHandle = createMutationHandle()
  const mutationTypes = createMutationTypes(cleanFormValues)
  const mutationValues = createMutationValues(cleanFormValues)
  const mutationVariables = getMutationVariables(cleanFormValues)

  const submissionData = await axios.post(BASEURL + '/actions/graphql/api', {
        query: `
           mutation FormQuery(${mutationTypes}){
            ${mutationHandle}(${mutationValues}){
              id
            }
           }
    `,
        variables: mutationVariables
      }, {
        headers: {
          'Authorization': "Bearer " + gqlToken
        }
      }
  )

if (submissionData.data.errors) {
  // Handle GraphQL errors
  const graphqlErrors = submissionData.data.errors
  const formErrors: string[] = []
  const fieldErrors: Record<string, string[]> = {}

  graphqlErrors.forEach((error: any) => {
    if (error.extensions && error.extensions.category === 'validation') {
      // Field-specific errors
      Object.entries(error.extensions.validation).forEach(([field, messages]) => {
        fieldErrors[field] = Array.isArray(messages) ? messages : [messages as string]
      })
    } else {
      // Form-level errors
      formErrors.push(error.message)
    }
  })

  // Use FormKit's setErrors to set both form and field errors
  setErrors(formname, formErrors, fieldErrors)
} else if (submissionData.data.data) {
  // Submission successful
  reset('formie-form')
  // You can add additional success handling here, e.g. showing a success message
  console.log('Form submitted successfully')
}
}
const createMissingValues = async () => {
  const formJson = flattenFormValues(formValues.value);
  const allRows = flatMap(form.value.pages, 'rows');
  const allFields = flatMap(allRows, 'rowFields');

  const promises = allFields.map((field) => {
    if (field.displayName == 'FileUpload') {
      let fieldValue = formJson[field.handle];
      if (!fieldValue.length) return Promise.resolve();
      const formData = new FormData();
      fieldValue.forEach((file: any) => {
        if (file.file) {
          formData.append(`file[]`, file.file);
        }
      });
      formData.append('fieldHandle', field.handle);
      return axios.post(BASEURL + '/actions/green-care/formie/save-files', formData, {
        headers: { "Content-Type": "multipart/form-data" }
      }).then((res) => {
        const {data} = res
        const jsonData = JSON.parse(data);
        if (jsonData.errors && jsonData.errors[field.handle]) {
          // Handle errors for this field
          if (errors.value) {
            const pageIndex = form.value.pages.findIndex((page: any) => 
              page.rows.some((row: any) => 
                row.rowFields.some((f: any) => f.handle === field.handle)
              )
            );
            if (pageIndex !== -1 && errors.value[pageIndex]) {
              errors.value[pageIndex][field.handle] = jsonData.errors[field.handle];
            }
          }
        } else {
          // Assuming successful upload returns an array of file IDs
          fieldValue = jsonData || [];
          formJson[field.handle] = fieldValue
        }
      }).catch((error) => {
        console.error(`Error uploading files for ${field.handle}:`, error);
      });
    }

    if (field.displayName == 'ExtendableEntries') {
      let fieldValue = formJson[field.handle];
      const newEntries = fieldValue.filter((entry: string) => {
        return isNaN(entry);
      });
      if (!newEntries.length) return Promise.resolve();

      return axios.post(BASEURL + '/actions/green-care/formie/save-extendable-entries', {
        entries: newEntries,
        entryType: field.entries[0].sectionHandle
      }).then(({data}) => {
        const jsonData = JSON.parse(data);
        for (let i = 0; i < jsonData.length; i++) {

          fieldValue.push(jsonData[i].id.toString());
          fieldValue = fieldValue.filter((entry: string) => {
            return !newEntries.includes(entry)
          })
          updateFieldValue(formValues.value, field.handle, fieldValue)
        }
      });
    }
    return Promise.resolve();
  });

  await Promise.all(promises);
  return formJson
};
const flattenFormValues = (values: any) => {
  let fieldValues: { [key: string]: any } = {};
  const collect = (obj: any) => {
    for (let key in obj) {
      if (obj.hasOwnProperty(key)) {
        if (Array.isArray(obj[key])) {
          if (!fieldValues[key]) {
            fieldValues[key] = [];
          }
          fieldValues[key] = fieldValues[key].concat(obj[key]);
        } else if (typeof obj[key] === 'object' && obj[key] !== null) {
          collect(obj[key]);
        } else {
          if (!fieldValues[key]) {
            fieldValues[key] = obj[key];
          } else if (Array.isArray(fieldValues[key])) {
            fieldValues[key].push(obj[key]);
          } else {
            fieldValues[key] = [fieldValues[key], obj[key]];
          }
        }
      }
    }
  };
  collect(values);
  return fieldValues;
};
const createMutationHandle = () => {
  return `save_${formname}_Submission`;
}
const updateFieldValue = (values: any, handle: string, newValue: any) => {
  const update = (obj: any) => {
    Object.keys(obj).some(key => {
      if (key === handle) {
        obj[key] = newValue;
        return true;
      } else if (typeof obj[key] === 'object' && obj[key] !== null) {
        return update(obj[key]);
      }
      return false;
    });
  };
  update(values);
};

const getFormFieldMeta = () => {
  const allRows = flatMap(form.value.pages, 'rows')
  const allFields = flatMap(allRows, 'rowFields')
  return flatMap(allFields, ({handle, inputTypeName, displayName}) => {
    return {handle, inputTypeName, displayName};
  });
}
const createMutationTypes = (cleanValues:any) => {
  const flatFormValues = cleanValues
  const types = getFormFieldMeta().map(({handle, inputTypeName}) => {
    if (Object.keys(flatFormValues).find((value: any) => value == handle))
      return `$${handle}: ${inputTypeName}`;
    else return;
  });

  form.value.captchas.forEach((captcha:any) => {
    types.push(`$${captcha.handle}: FormieCaptchaInput`)
  })


  return types.join(", ")
}
const createMutationValues = (cleanValues:any) => {
  const flatFormValues = cleanValues;
  const values = flatMap(getFormFieldMeta(), 'handle').map((key) => {
    if (Object.keys(flatFormValues).find((value: any) => value == key))
      return `${key}:$${key}`
    else return;
  })

  form.value.captchas.forEach((captcha) => {
    values.push(`${captcha.handle}: $${captcha.handle}`);
  });
  return values.join(",")
}
const getMutationVariables = (cleanFormValues:any) => {
  const formJson = cleanFormValues
  const mutationTypes = getFormFieldMeta()
  mutationTypes.forEach(async (info) => {
    let value = formJson[info.handle];
    if (info.handle == 'betriebBilder')
      if (typeof value === 'undefined') {
        return;
      }

    if(info.displayName == 'FileUpload'){
      value = value.map((file:any)=>{
        return {
          assetId: file
        }
      })
    }
    // Fix up any objects that look like arrays
    if (isPlainObject(value) && info.inputTypeName !== '[FileUploadInput]') {
      if (typeof value[0] !== 'undefined') {
        value = Object.values(value);
      }
    }

    if (info.inputTypeName === 'Int') {
      value = parseInt(formJson[info.handle], 10);
    }

    if (info.inputTypeName === '[Int]') {
      if (isPlainObject(value)) {
        value = Object.values(value);
      }

      value = value.map((item) => {
        return parseInt(item, 10);
      });
    }

    if (info.inputTypeName === 'Number') {
      value = Number(formJson[info.handle]);
    }

    if (info.inputTypeName === '[Number]') {
      if (isPlainObject(value)) {
        value = Object.values(value);
      }

      value = value.map((item) => {
        return Number(item);
      });
    }

    formJson[info.handle] = value;
  });

  // Add in any captcha tokens generated when we queried the form.
  form.value.captchas.forEach((captcha) => {
    formJson[captcha.handle] = {
      name: captcha.name,
      value: captcha.value,
    };
  });
  return formJson;
}

const jumpToTop = ()=>{
  const formElement = document.getElementById(formname);
  if (formElement) {
    formElement.scrollIntoView({ behavior: 'smooth', block: 'start' });
  }
}


</script>

<template>
  <div class="form-container max-w-form mx-auto" v-if="!loading && form">
    <FormKit :id="formname" type="form" :actions="false" v-model="formValues" use-local-storage
             @submit="createSubmission">
      <FormKit type="multi-step" name="tabs" tabStyle="tabs" :allow-incomplete="false"
               wrapper-class="!max-w-none !shadow-none"
               steps-class="!bg-transparent !border-none">
        <template #tabs="{ steps, handlers, activeStep, fns }">
          <div class="flex justify-center">

            <div class="flex m:flex-col gap-4 px-10 pt-10 items-center m:w-fit w-full" v-if="form.pages.length > 1">
              <template v-for="(step, index) in steps" :key="index">
              <div class="flex" :class="{'minM:ml-0 minM:mr-auto ': index == 0, 'minM:ml-auto minM:mr-0': steps.length-1 === index}">
                <div
                class="step-label whitespace-nowrap text-sm font-medium cursor-pointer"
                :class="{
                  'text-gray-500': steps.findIndex(s => s.id === activeStep) < index,
                  'text-green-600': step.isValid || (steps.findIndex(s => s.id === activeStep) >= index && !step.isValid),
                  'text-red-600': !step.isValid && steps.findIndex(s => s.id === activeStep) > index
                }"
                    @click="()=>{handlers.setActiveStep(step)();jumpToTop()}"
                    >
                    {{ step.label }}
                  </div>
                </div>
                <div v-if="steps.length-1 !== index" class="w-full h-[1px] grow ml-2"
                :class="steps.findIndex(s => s.id === activeStep) > index ? 'bg-primary-600' : 'bg-gray-500'"></div>
              </template>
            </div>
          </div>
        </template>
        <template v-for="(page, index) in form.pages">

          <FormKit v-if="page" type="step" :label="page.label!" :name="page.id" :id="page.id!">
            <div v-for="row in page.rows" class="py-4" :class="`grid gap-6 m:gap-4 grid-cols-${row.rowFields.length} m:grid-cols-1`">
              <formie-field v-if="row" v-for="field in row.rowFields" :key="`${form.handle}-${field.id}`" :form-values="formValues"
                            :field="field"/>
            </div>
            <template v-if="index != 0" #stepPrevious="{handlers,node}">
              <button class="btn-secondary py-2.5 px-5 ml-0 mr-auto" @click.prevent="()=>{handlers.incrementStep(-1)();jumpToTop()}">
                {{ t("Zurück") }}
              </button>
            </template>
            <template #stepNext="{handlers,node}">
              <button class="btn-primary py-3 px-6 ml-auto mr-0"
                      @click.prevent="() => {
                        if (index !== form.pages.length - 1) {
                          handlers.incrementStep(1)();
                          jumpToTop();
                        } else {
                          submitForm(formname);
                        }
                      }">
                {{ index !== form.pages.length-1 ? t("Nächster Schritt") : t("Formular Absenden") }}
              </button>
            </template>
          </FormKit>
        </template>
      </FormKit>
    </FormKit>
  </div>
  <div v-if="loading || !form">
    <div role="status" class="flex justify-center mt-10">
      <svg aria-hidden="true" class="w-32 h-32 text-gray-200 animate-spin dark:text-gray-600 fill-primary-600" viewBox="0 0 100 101" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z" fill="currentColor"/>
        <path d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z" fill="currentFill"/>
      </svg>
      <span class="sr-only">Loading...</span>
    </div>

  </div>
</template>


<style lang="scss">
.formkit-asterisk {
  @apply text-red-600;
}
</style>
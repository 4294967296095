<script setup lang="ts">
import {Field_Entries, FieldInterface} from "../../../../gql/types/types";
import {FormKit} from "@formkit/vue"
import {computed} from "vue";
import CustomCheckbox from "./includes/CustomCheckbox.vue";

const {field} = defineProps({
  field: {
    type: Object as () => Field_Entries,
    required: true
  },
  formValues:{
    type:Object,
    required:true
  }
})
const options = computed(() => {
  return field.entries?.map((entry) => {
    if (entry)
      return {
        label: entry.title,
        value: entry.id
      }
    else return null
  })
})
</script>

<template>
  <FormKit v-if="field.displayType=='checkboxes'" type="checkbox" :id="`field:${field.handle}`" :validation="field.required ? 'required|min:1': '' " :name="field.handle!" :options="options" :label="field.label"  :outer-class="field.labelPosition == 'verbb\\formie\\positions\\LeftInput' ? 'grid grid-cols-2 gap-6 justify-between !mb-0 m:block': '' " :fieldset-class="field.labelPosition == 'verbb\\formie\\positions\\LeftInput'? 'contents': ''" label-class="ml-6">
    <template #decorator>
      <custom-checkbox />
    </template>
  </FormKit>
  <FormKit v-if="field.displayType=== 'dropdown'" type="select" :id="`field:${field.handle}`" :validation="field.required ? 'required|min:1' : '' " :name="field.handle" :options="options" :label="field.label"></FormKit>
</template>

<style scoped lang="scss">

</style>